<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        target-components-header

        div(class="target-content")
          div(class="target-content-item") ターゲット名
            span(class="caution-t") *
              span(class="m-l" style="color: black") ※全角20文字、半角40文字以内
            p(v-if="nameLengthOver" style="color: red") 文字数超過しています
            input(v-bind:disabled="preview_status" v-model="data.name" @input="validateTargetName()" class="input-g-w-border")
            div {{ `${(data.name ? countLength(data.name) : 0)} / 40` }}

          div(class="target-content-item") 目標ポイント
            span(class="caution-t") *
            select(v-bind:disabled="preview_status" v-model="data.target_point" class="input-g-w-border")
              option(value="-1") 選択してください
              option(v-for="option in ui.options.point" :value="option") {{ option }}

          div(class="target-content-item") チャレンジ内容
            span(class="caution-t") *
            textarea(v-bind:disabled="preview_status" v-model="data.content" maxlength="255" rows="3" class="input-g-w-border")

          div(class="target-content-item") バナー設定
            span(class="caution-t") *
            img(v-show="data.banner_store_path" :src="$store.state.system.config.storage_addr_list.public + data.banner_store_path")
            input#fileUpload(type="file", v-show="false", @change="onUpload")
          div(v-bind:disabled="preview_status"  @click="chooseFile()")  <img src="/img/file_p.png">

          div(class="caution-t") ※＊は必須項目です
          div(class="caution-t") ※比率16:9（横長）、500KB以上の際は圧縮いたします
          div(class="caution-t")  （推奨サイズ：615×346ピクセル、500KB以下）

        div(v-if="!is_s1" class="caution-t target-caution") ※現在のターゲットを達成するまで新規ターゲットへの挑戦はできません
        component-error(:errors="errors" v-if="errors.length")
        div(class="bottom-button")
          div(@click="onSave()" ) <img src="/img/shitagaki_p.png">
          div(v-bind:disabled="preview_status" @click="onSubmit()" v-if="is_s1") <img src="/img/chousen_p.png">

      div(class="content-right")
        div(v-if="!ui.isConfirm")
          pr-banner

        div(v-else)
          div(class="right-content-border")
            div ターゲット名
            div {{ data.name }}
            img(:src="$store.state.system.config.storage_addr_list.public + data.banner_store_path")
            div 目標ポイント
            div {{ data.target_point }}
            br
            div チャレンジ内容
            div(class="content-text") {{ data.content }}
            br
            div(class="caution-t") ＜登録時の注意事項＞
            div(class="caution-t") ・目標ギフトポイントを達成するまでターゲットは終了になりません。
            div(class="caution-t") ・一度設定したターゲットの内容は途中で変更できません。
            div(class="caution-t") ・同一期間に設定できるターゲットは1件のみとなります。
            div(class="caution-t") ・チャレンジ内容には必ず上位の方への特典を記載してください。
            div(class="caution-t") ・ターゲットバナーやチャレンジ内容等が契約および注意事項に違反しているとAライブが判断した際は、強制終了となる場合がございます。
            br
            div このターゲットに挑戦しますか？

            div(class="button-gemini")
              div(@click="onConfirm()") <img src="/img/yes_p.png">
              div(@click="onCancel()") <img src="/img/no_p.png">

    component-footer-index
</template>

<style src="./New.scss" lang="scss" scoped>
</style>

<script>
import _ from 'lodash';
import TargetComponentsHeader from "./components/Header";
import { getTargetListS1, getTargetListS2, uploadBanner, createTargetS1, createTargetS2, updateTargetS1, updateTargetS2 } from '@/api/target'
import Compressor from 'compressorjs';
import { countLength } from '@/utils/tools'
import { validStringCount } from '@/utils/inputLimit';

export default {
  name: "SettingTargetNew",
  components: {
    TargetComponentsHeader
  },
  data() {
    return {
      data: {
        target_id: undefined,
        name: undefined,
        target_point: undefined,
        content: undefined,
        banner_store_path: undefined,
      },
      is_s1: true,
      preview_status: false,
      ui: {
        isConfirm: false,
        options: {
          point: undefined,
        }
      },
      file:undefined,
      nameLengthOver: false,
      errors: [],
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    let point1 = _.range(1, 4).map(v => `${v * 10000}`);
    let point2 = _.range(1, 11).map(v => `${v * 50000}`);
    for(let item of point2) {
      point1.push(item)
    }
    this.ui.options.point = point1;
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const ret1 = await getTargetListS1();
      const ret2 = await getTargetListS2();
      if (ret1.data.data[0]) {
        this.data.target_id = ret1.data.data[0].id;
        this.data.name = ret1.data.data[0].name;
        this.data.target_point = ret1.data.data[0].target_point;
        this.data.content = ret1.data.data[0].content;
        this.data.banner_store_path = ret1.data.data[0].banner_store_path;
      }
      this.is_s1 = ret2.data.data[0] ? false : true;
    },
    async onSave() {
      if (!this.validationCheck()) { return }
      if (this.data.target_id) {
        await updateTargetS1(this.data)
      } else {
        await createTargetS1(this.data)
      }
      await this.init();
      this.$message({
        showClose: true,
        message: '完了しました',
        type: 'success'
      });
    },
    resetData(){
      this.data = {
        target_id: undefined,
        name: undefined,
        target_point: undefined,
        content: undefined,
        banner_store_path: undefined,
      }
    },
    onSubmit() {
      if (!this.validationCheck()) { return }
      if(this.data.name && this.data.target_point && this.data.content && this.data.banner_store_path ) {
        this.ui.isConfirm = true;
        this.preview_status = true;
      } else {
        this.$message({
          showClose: true,
          message: '完了しました',
          type: 'success'
        });
      }
    },
    async onConfirm() {
      if (this.data.target_id) {
        await updateTargetS2(this.data)
      } else {
        await createTargetS2(this.data);
      }
      await this.init()
      this.$message({
        showClose: true,
        message: '完了しました',
        type: 'success'
      });
      this.resetData()
      this.ui.isConfirm = false;
    },
    onCancel() {
      this.ui.isConfirm = false;
      this.preview_status = false;
    },

    chooseFile() {
      document.getElementById("fileUpload").click();
    },
    async onUpload(event) {
      let _this = this;
      this.file = event.target.files[0];
      if (!this.file) {
        return;
      }
      let imagSize = this.file.size;
      if(imagSize < 1024 * 500) {
        var formData = new FormData();
        formData.append("file", event.target.files[0]);
        try {
          const { data } = await uploadBanner(formData);
          _this.data.banner_store_path = data.store_path;
        }catch(e) {
          console.log(e)
          _this.$alert('アップロードに失敗しました。不正なファイル形式です。')
        }

      } else {
        new Compressor(this.file, {
        convertSize: 500000, // 500KB
        async success(result) {
          var formData = new FormData();
          formData.append("file", result);
          if (formData) {
            try {
              const { data } = await uploadBanner(formData);
              _this.data.banner_store_path = data.store_path;
            }catch(e) {
              console.log(e)
              _this.$alert('アップロードに失敗しました。不正なファイル形式です。')
            }
          }
        },
        error(err) {
          console.log(err.message);
        }
        })
      }
    },
    validateTargetName() {
      if(countLength(this.data.name) > 40) {
        this.nameLengthOver = true;
        return
      }
      this.nameLengthOver = false;
    },
    countLength(str) {
      return countLength(str);
    },
    validationCheck() {
      this.errors = [];
      let errorStrings = [];
      if (!this.data.name) {
        errorStrings.push('※[ターゲット名]を入力してください\n');
      }
      if (!validStringCount(this.data.name, 40)) {
        errorStrings.push('※[ターゲット名]は全角20文字、半角40文字以内で入力してください\n');
      }
      if (!this.data.target_point) {
        errorStrings.push('※[目標ポイント]を入力してください\n');
      }
      if (!this.data.content) {
        errorStrings.push('※[チャレンジ内容]を入力してください\n');
      }
      if (!this.data.banner_store_path) {
        errorStrings.push('※バナーを設定してください\n');
      }

      if (errorStrings.length) {
        this.errors = errorStrings;
        return false;
      }
      return true;
    }
  }
};
</script>
